import { DateTime } from 'luxon';

export interface AddOrUpdateActivityInSequenceRequest extends ActivityInSequenceRequestBase {
  parentActivityGuid: string
  childOrder: number
  doNotCloneCompletionTarget: boolean
}

export interface ActivityInSequenceRequestBase {
  childActivityGuid: string
}

export interface RemoveActivityFromSequence extends ActivityInSequenceRequestBase {
}

export interface Segmentation extends CommandModelBase {
  activityGuid?: string
  type: SegmentationType
  question?: string
  squareGuid: string
  isStarred: boolean
  isArchived: boolean
  surveyQuestions?: SurveyQuestion[]
}

export enum SegmentationType {
 Survey = 0,
 Manual = 1,
 PlatformBehavior = 2,
 ActivityBehavior = 3,
 Profile = 4,
}

export interface SurveyQuestion extends CommandModelBase {
  question?: string
  type: QuestionType
  name?: string
  activityId: number
  segmentations?: Segmentation[]
}

export enum QuestionType {
 Single = 1,
 Multi = 2,
 Grid = 3,
 Open = 4,
 Numeric = 5,
 NumericList = 6,
 OpenList = 7,
 Info = 8,
 GridOneRow = 9,
 OpenOther = 10,
}

export interface CommandModelBase {
  id: number
  guid: string
  dateModified?: DateTime
}

export interface MissingSegmentationsRequest {
  activityGuid: string
}

export interface ArchivedSegmentationsRequest {
  activityGuid: string
}

export interface CreateActivityResponse {
  id: number
  guid: string
  name?: string
  cloneJobId?: string
  communicationGuid?: string
  conversationGuid?: string
  topicTitle?: string
}

export interface CreateActivityRequest {
  name?: string
  squareGuid: string
  programGuid: string
  clonedActivityGuid: string
  squareJobId?: string
  onBehalfOfSquareParticipantGuid?: string
}

export interface GetSurveyLinkResponse {
  link?: string
  sampleGuid: string
}

export enum ChannelType {
 None = 0,
 CommunicationCard = 1,
 EmailNewsletter = 2,
 CommunicationText = 4,
 ResearchActivityChallenge = 5,
 ResearchActivityEmail = 6,
 ResearchActivityReminder = 7,
 ResearchActivityIncomplete = 8,
 ResearchActivityChallengeHome = 9,
 IncentiveCard = 10,
 ResearchActivityMobileApp = 11,
 CommunicationNews = 12,
 Notification = 13,
 NotificationUpdate = 14,
 CommunicationNewsHome = 15,
 DiscussionThread = 16,
 PageIntro = 17,
 CustomAppNotification = 18,
 DigestEmail = 19,
 PageCta = 20,
 ProfilePage = 21,
}

export interface CreateRespondentAndReturnScreenerSurveyLinkRequest {
  activityGuid: string
  partnerId: number
  bId?: string
  subsource?: string
  backgroundVariables?: BackgroundVariableValuePair[]
  country?: string
  firstName?: string
  lastName?: string
  email?: string
  culture?: string
}

export interface BackgroundVariableValuePair {
  variable?: string
  value?: string
}

export interface SyncActivitiesQuestionnaireRequest {
  activityGuids?: string[]
}

export interface UpdateActivityConfigRequest {
  guid: string
  surveyId?: string
  inlineSurveyUri?: string
  offset: number
  startDate: DateTime
  endDate: DateTime
  credits: number
  completionPoints?: CompletionPoints
  raffleExecutionDate?: DateTime
  rewards?: RaffleReward[]
  themeGuid: string
  activityCompleteRedirectGuid?: string
  completionTarget?: number
  isAppScreener?: boolean
}

export interface CompletionPoints {
  qualified?: number
  quotaFull?: number
  screenedOut?: number
}

export interface RaffleReward extends CommandModelBase {
  activityGuid?: string
  squareGuid: string
  raffleGuid?: string
  amount?: number
  units?: number
  value: number
  type: RaffleType
  currencyId: number
  currencyGuid: string
  countryIso?: string
  country?: string
  segmentGuid?: string
  isAutomaticApproved: boolean
  description?: string
  dateCreated?: DateTime
}

export enum RaffleType {
 Instant = 1,
 Periodic = 2,
 Manual = 3,
 SavingUp = 4,
}

export interface UpdateActivityInfoRequest {
  guid: string
  name?: string
  type: SquareActivityType
  format: SquareActivityFormat
  language?: string
  description?: string
  piiSensitive: boolean
  complexity: number
  squareJobId?: string
  activityAccessSquareParticipantGuids?: string[]
}

export enum SquareActivityType {
 None = 0,
 ConfirmitSurvey = 1,
 QualitativeResearch = 2,
 CheckMarketSurvey = 3,
 PublicQualitativeResearch = 4,
 PrivateQualitativeResearch = 5,
 DecipherSurvey = 6,
 PublicScoutResearch = 7,
 PrivateScoutResearch = 8,
 Discussion20Research = 9,
 DecipherDiarySurvey = 11,
 Observation = 12,
}

export enum SquareActivityFormat {
 None = 0,
 Screener = 1,
 Profile = 2,
 Research = 3,
}

export interface UpdateActivitySegmentationRequest {
  activityGuid: string
  segmentationGuids?: string[]
}

export interface PublishActivityRequest {
  guid: string
}

export interface CompleteScreenerActivityResponse {
  redirectUrl?: string
  outComeCode: SurveyOutcomeCode
  ssoToken?: string
  shouldReturnSsoToken: boolean
}

export enum SurveyOutcomeCode {
 None = 0,
 Qualified = 1,
 Screened = 2,
 QuotaFull = 3,
}

export interface CompleteScreenerActivityRequest {
  intakeGuid: string
  outcomeCode: SurveyOutcomeCode
  outcomeGuid: string
  squareParticipantGuid: string
}

export interface PinActivityRequest {
  activityGuid: string
}

export interface UpdateActivityQualSpecifyResponse {
  activityQual?: ActivityQual
  completeCriterias?: ActivityQualCompleteCriteria[]
}

export interface ActivityQual extends CommandModelBase {
  activityGuid: string
  communicationGuid?: string
  conversationGuid?: string
  conversationId?: number
  context: ActivityContextType
  visibility: ActivityVisibilityType
  title?: string
  description?: string
  prePopulateDescription?: string
  totalParticipants?: number
  squareGuid: string
  qualifiedParticipants?: number
  isOneByOne: boolean
  activityType: SquareActivityType
  openPostUsername?: string
  conversationFocusType: ConversationFocusType
  contributionType?: ActivityContributionType
  moderatorCuration: boolean
  visibilityConditionType?: ActivityVisibilityConditionType
  visibilityConditionBufferHours?: number
  visibilityConditionDate?: DateTime
}

export enum ActivityContextType {
 Public = 0,
 Private = 1,
}

export enum ActivityVisibilityType {
 Influenced = 0,
 Uninfluenced = 1,
 Private = 2,
}

export enum ConversationFocusType {
 Text = 1,
 Visual = 2,
}

export enum ActivityContributionType {
 Standard = 0,
 Diary = 1,
}

export enum ActivityVisibilityConditionType {
 Default = 0,
 AcceptedContribution = 1,
}

export interface ActivityQualCompleteCriteria extends CommandModelBase {
  activityQualGuid: string
  criteriaType: ActivityQualCompleteCriteriaType
  value: number
}

export enum ActivityQualCompleteCriteriaType {
 Word = 1,
 Image = 2,
 Video = 3,
 OnlineMedia = 4,
 Attachment = 5,
}

export interface UpdateActivityQualSpecifyRequest {
  activityGuid: string
  context: ActivityContextType
  visibility: ActivityVisibilityType
  startDate: DateTime
  endDate: DateTime
  offset: number
  themeGuid?: string
  completionTarget?: number
  completeCriterias?: ActivityQualCompleteCriteria2[]
  completionPoints?: CompletionPoints
  squareParticipantGuid?: string
  onBehalfOfSquareParticipantGuid?: string
}

export interface ActivityQualCompleteCriteria2 {
  criteriaType: ActivityQualCompleteCriteriaType
  value: number
}

export interface UpdateActivityQualSpecifyForDividedTypeResponse {
  activityQual?: ActivityQual
}

export interface UpdateActivityQualSpecifyForDividedTypeRequest {
  activityGuid: string
  visibility: ActivityVisibilityType
  startDate: DateTime
  endDate: DateTime
  offset: number
  themeGuid?: string
  completionTarget?: number
  completionPoints?: CompletionPoints
  squareParticipantGuid?: string
  onBehalfOfSquareParticipantGuid?: string
  contributionType?: ActivityContributionType
  moderatorCuration: boolean
  visibilityConditionType?: ActivityVisibilityConditionType
  visibilityConditionBufferHours?: number
  visibilityConditionDate?: DateTime
}

export interface UpdateActivityQualBuildRequest {
  activityGuid: string
  conversationGuid: string
  communicationGuid?: string
  title?: string
  description?: string
  prePopulateDescription?: string
  isOneByOne: boolean
  squareParticipantGuid?: string
  onBehalfOfSquareParticipantGuid?: string
  conversationFocusType?: ConversationFocusType
}

export interface UpdateActivityQualPublishRequest {
  activityGuid: string
  squareParticipantGuid?: string
  onBehalfOfSquareParticipantGuid?: string
}

export interface ResetActivityRequest {
  activityGuid: string
}

export interface GetRespondentRequest {
  sampleGuid: string
  isTest: boolean
  format: SquareActivityFormat
}

export interface CreateOrUpdateActivityRecruitmentPartnerRequest {
  activityGuid?: string
  partnerId: number
  linkType: RecruitmentPartnerLinkType
  callbackType: RecruitmentPartnerCallbackType
  completeUrl?: string
  screenedUrl?: string
  quotaUrl?: string
  useSubsource: boolean
  variables?: CreateOrUpdateBackgroundVariableRequest[]
  consents?: CreateOrUpdateActivityRecruitmentPartnerConsentRequest[]
}

export enum RecruitmentPartnerLinkType {
 Closed = 0,
 Open = 1,
}

export enum RecruitmentPartnerCallbackType {
 Pixel = 0,
 Redirect = 1,
}

export interface CreateOrUpdateBackgroundVariableRequest {
  guid?: string
  name?: string
  label?: string
  values?: CreateOrUpdateBackgroundVariableValueRequest[]
}

export interface CreateOrUpdateBackgroundVariableValueRequest {
  guid?: string
  value?: string
  label?: string
}

export interface CreateOrUpdateActivityRecruitmentPartnerConsentRequest {
  consent: Consent
  validityExpirationDate?: DateTime
  isGiven: boolean
}

export enum Consent {
 AsynchronousResearch = 0,
}

export interface DeleteActivityRecruitmentPartnerRequest {
  guid: string
}

export interface SsoResponse {
}

export interface MigrateSquareParticipantsToADLoginRequest {
  initialEmail?: string
  currentEmail?: string
  adObjectId?: string
  communicationEmail?: string
  squareParticipantGuid: string
  role: Role
}

export enum Role {
 NoRoleYet = 0,
 Human8 = 1,
 Observer = 2,
 Participant = 3,
 ClientApi = 4,
 ServiceApi = 5,
 SsoUser = 6,
 ProfessionalAdmin = 7,
 AzureDevOps = 8,
 ClientAdmin = 9,
 ClientEditor = 10,
 All = 1000,
}

export interface CheckMarketWebHookRequest {
  surveyId?: string
  hookKey?: string
  webhookId: number
}

export interface PinClientRequest {
  clientGuid: string
}

export interface UpdateClientResponse {
  id: number
}

export interface UpdateClientRequest {
  guid: string
  adminName?: string
  relatedClientGuids?: string[]
}

export interface SaveClientConfigurationWelcomeMessageRequest {
  clientGuid: string
  title?: string
  message?: string
}

export interface SaveClientConfigurationVideoStorageRequest {
  clientGuid: string
  videoStorage?: ClientVideoStorage
  videoQuality: ClientVideoQuality
  videoPostJob: boolean
}

export enum ClientVideoStorage {
 AzureMediaServices = 0,
 Mux = 1,
 Human8MediaService = 2,
}

export enum ClientVideoQuality {
 Low = 0,
 Medium = 1,
 High = 2,
}

export interface SaveClientConfigurationColorRequest {
  clientGuid: string
  color?: string
}

export interface CommunicationChannel extends CommandModelBase {
  communicationGuid: string
  channelType: ChannelType
  title?: string
  message?: string
  startDateTime?: DateTime
  startDateOffset?: number
  endDateTime?: DateTime
  endDateOffset?: number
  callToActionText?: string
  editUrl?: string
  callToActionUrl?: string
  markedAsDeleted: boolean
  sticky: boolean
  dateLastDistributed?: DateTime
  singleShot: boolean
  openInNewTab: boolean
  squareGuid: string
  isPublished: boolean
  showOnHomepage: boolean
  showOnPublicPage: boolean
  isVisible: boolean
  cardType: CardType
  sequenceRole?: ActivitySequenceRole
  activityGuid?: string
  targetingStatus: TargetingStatus
  newsPageGuid?: string
}

export enum CardType {
 None = 0,
 ManualRewards = 1,
 AutomaticSavingUp = 2,
 ManualSavingUp = 3,
}

export enum ActivitySequenceRole {
 Unsequenced = 0,
 Parent = 1,
 Child = 2,
}

export enum TargetingStatus {
 Awaiting = 0,
 InProgress = 1,
 Done = 2,
}

export interface CommunicationChannelBase {
  guid?: string
  title?: string
  channelType: ChannelType
  communicationGuid: string
}

export interface CreateUpdateCommunicationResearchActivityResponse {
  syncedCommunicationChannelGuids?: string[]
  communicationGuid: string
}

export interface CreateUpdateBaseCommunicationResearchActivityRequest {
  communicationGuid: string
  activityGuid: string
  communicationChannels?: CreateUpdateCommunicationResearchActivityChannelRequest[]
}

export interface CreateUpdateCommunicationResearchActivityChannelRequest extends CommunicationResearchActivityChannelBaseDetails {
  rewards?: RaffleReward2[]
}

export interface RaffleReward2 extends CommandModelBase {
  activityGuid?: string
  raffleGuid?: string
  amount?: number
  value: number
  type: RaffleType
  currencyId: number
  segmentGuid?: string
  isAutomaticApproved: boolean
  description?: string
}

export interface CommunicationResearchActivityChannelBaseDetails extends CommunicationChannelBaseDetails {
  footerText?: string
  newFooter?: CreateCommunicationFooterRequest
  sendEmailDelay?: number
  softLaunchNumber?: number
  publishOption?: PublishResearchActivityOption
}

export interface CreateCommunicationFooterRequest {
  id?: number
  guid?: string
  name?: string
  footerText?: string
  squareGuid?: string
}

export enum PublishResearchActivityOption {
 HardLaunch = 1,
 SoftLaunch = 2,
}

export interface CommunicationChannelBaseDetails extends CommunicationChannelBase {
  message?: string
  callToActionText?: string
  startDateTime?: DateTime
  startDateOffset?: number
  endDateTime?: DateTime
  endDateOffset?: number
  stimuli?: number[]
  openInNewTab?: boolean
  sticky: boolean
  showOnHomepage: boolean
  newsPageGuid?: string
  isVisible: boolean
  cardType: CardType
}

export interface UpdateCommunicationResearchActivityRequest {
  communicationChannel?: UpdateCommunicationResearchActivityChannelRequest
}

export interface UpdateCommunicationResearchActivityChannelRequest extends CommunicationResearchActivityChannelBaseDetails {
}

export interface PublishCommunicationRequest {
  communicationGuid: string
  channelPublishOptions?: ChannelPublishOptionsRequest[]
}

export interface ChannelPublishOptionsRequest {
  guid: string
  softLaunchNumber?: number
  publishOption?: PublishResearchActivityOption
}

export interface UpdateCommunicationTitleRequest {
  communicationGuid: string
  title?: string
}

export interface CreateCommunicationProfileActivityResponse {
  communicationGuid: string
}

export interface CreateCommunicationProfileActivityRequest {
  activityGuid: string
}

export interface NewsPage extends CommandModelBase {
  name?: string
  squareGuid: string
}

export interface DeleteCommunicationChannelResponse {
  id: number
  guid: string
}

export interface DeleteCommunicationChannelRequest {
  channelGuid: string
  communicationGuid: string
}

export interface HardLaunchCommunicationChannelRequest {
  channelGuid: string
}

export interface ResearchActivitySendTestMailRequest {
  activityGuid: string
  communicationGuid: string
  channelType: ChannelType
  callToActionText?: string
  title?: string
  message?: string
  footerText?: string
  rewards?: RewardItem[]
  loggedInInSitesUser?: IPrincipal
}

export interface RewardItem {
  guid: string
  description?: string
  amount: number
  value: number
  currencyName?: string
}

export interface IPrincipal {
  identity?: IIdentity
}

export interface IIdentity {
  name?: string
  authenticationType?: string
  isAuthenticated: boolean
}

export interface NewsletterSendTestMailRequest {
  channelType: ChannelType
  communicationGuid: string
}

export interface AvailableSquarePageUrlsResponse {
  pageUrls?: string[]
}

export interface CreateCommunicationResponse {
  id: number
  guid: string
}

export interface CreateBaseCommunicationRequest {
  id?: number
  guid?: string
  squareGuid: string
  title?: string
}

export interface RemoveCommunicationRequest {
  communicationGuid: string
}

export interface SendTicketRequest {
  type: ServiceDeskType
  questionType: ServiceDeskQuestionType
  subject?: string
  message?: string
  squareParticipantGuid: string
  browserId?: string
  os?: string
}

export enum ServiceDeskType {
 MyAccount = 1,
 MyIncentives = 2,
 Technical = 3,
 Other = 4,
 ParticipantSupport = 13,
 DataDeletionRequest = 32,
 Activity = 33,
}

export enum ServiceDeskQuestionType {
 None = 0,
 MyAccount = 1,
 MyIncentives = 2,
 Technical = 3,
 Other = 4,
 DataDeletionRequest = 5,
 Activity = 6,
}

export interface AddConversationNoteResponse {
  guid: string
  username?: string
  conversationGuid: string
  text?: string
  isEditable: boolean
}

export interface AddConversationNoteRequest {
  conversationGuid: string
  text?: string
}

export interface UpdateConversationNoteRequest {
  guid: string
  text?: string
}

export interface DeleteConversationNoteRequest {
  guid: string
}

export interface AddConversationTagResponse {
  guid: string
  squareGuid: string
  conversationGuid: string
  text?: string
}

export interface AddConversationTagRequest {
  conversationGuid: string
  text?: string
}

export interface DeleteConversationTagResponse {
  isSingle: boolean
}

export interface DeleteConversationTagRequest {
  guid: string
}

export interface CreateUpdateQualConversationResponse {
  conversationGuid: string
}

export interface ConversationLikeRequest {
  conversationGuid: string
  squareParticipantGuid: string
}

export interface QualConversationLikeRequest extends ConversationLikeRequest {
  communicationSampleGuid: string
  activityGuid: string
}

export interface ConversationDeleteRequest {
  conversationGuid: string
}

export interface ConversationUnReadDiscussionDeleteRequest {
  conversationGuids?: string[]
}

export interface ConversationCensorRequest {
  guid: string
}

export interface ConversationSetRatingRequest {
  guid: string
  value: number
}

export interface CreateConversationStimuliStreamingUrlResponse {
  url?: string
}

export interface CreateConversationStimuliStreamingUrlRequest {
  stimuliGuid: string
}

export interface ConversationHighlightRequest {
  guid: string
  message?: string
  highlightedElementsCommonId?: string
  snippet?: string
  highlight: boolean
}

export interface UploadLinkForPhotoResponse {
  photoSasUrl?: string
  stimuliGuid: string
}

export interface UploadLinkForAttachmentResponse {
  attachmentSasUrl?: string
  attachmentGuid: string
}

export interface UploadLinkForVideoResponse {
  url?: string
  stimuliGuid: string
  id?: string
  isDiscussionNew: boolean
}

export interface LogErrorFromClientRequest {
  message?: string
  source?: string
}

export interface CancelUploadLinkForVideoRequest extends NewUploadLinkForVideoResponse {
}

export interface NewUploadLinkForVideoResponse {
  id?: string
  url?: string
}

export interface WebhookInternalData {
  uploadId?: string
  assetId?: string
  playbackId?: string
  metadata?: string
}

export interface UpdateConversationStimuliThumbnailUrlRequest {
  clientGuid: string
  stimuliGuid: string
  url?: string
  thumbnailUrl?: string
}

export interface UpdateConversationStimuliValueUrlRequest {
  clientGuid: string
  stimuliGuid: string
  url?: string
}

export interface UpdateConversationAttachmentUrlRequest {
  clientGuid: string
  attachmentGuid: string
  url?: string
}

export interface ConversationAcceptedRequest {
  conversationGuid: string
}

export interface UpdateStimulusRequest {
  guid: string
  id?: string
  value?: string
  type: ConversationStimuliType
  url?: string
  thumbnailUrl?: string
}

export enum ConversationStimuliType {
 Image = 0,
 Video = 1,
 Youtube = 2,
 Vimeo = 3,
}

export interface SaveHashtagsFromContentRequest {
  squareParticipantGuid: string
  contentArray?: string[]
  existingHashtagsWithGuid?: Record<string, string>
}

export interface CreateTagRequest {
  squareParticipantGuid: string
  text?: string
  existingTagGuid?: string
}

export interface SetVideoStimulusTranscriptionOldDiscussionsRequest extends SetVideoStimulusTranscriptionRequest {
}

export interface SetVideoStimulusTranscriptionRequest {
  'job_id': number
  status: string
  message: string
  data?: TranscriptionData
  'custom_properties': CustomProperties
}

export interface TranscriptionData {
  'transcript_original': string
  'transcript_en'?: string
  'summary_original': string
  'summary_en'?: string
}

export interface CustomProperties {
  clientCode?: string
  squareGuid: string
  discussionGuid?: string
}

export interface CreateOrUpdateDiscussionActivityResponse {
  guid: string
}

export interface CreateOrUpdateDiscussionActivityRequest {
  guid?: string
  activityGuid?: string
  discussionGuid?: string
  communicationGuid?: string
  appearance?: Appearance
  contributionType?: ActivityContributionType
  visibilityType: ActivityVisibilityType
  moderatorCuration: boolean
  isOneByOne: boolean
}

export enum Appearance {
 Forum = 1,
 Chat = 2,
 Titles = 3,
 Centered = 4,
}

export interface CloneDiscussionActivityResponse {
  hasVideoStillEncoding: boolean
  hasBrokenStimuli: boolean
}

export interface CloneDiscussionActivityRequest {
  clonedActivityGuid: string
  newActivityGuid: string
  fromSquareGuid: string
}

export interface RemovalResponse {
  success: boolean
}

export interface RemoveDiscussionActivityRequest {
  activityGuid: string
}

export interface IndexDiscussionActivitiesRequest {
  activityGuids?: string[]
  lastNDays?: number
}

export interface RemoveDiscussionsForSquareRequest {
  squareGuid: string
}

export interface CreateOrUpdateDiscussionResponse {
  id: number
  guid: string
}

export interface CreateOrUpdateDiscussionRequest {
  guid?: string
  title?: string
}

export interface CreateOrUpdateDiscussionActivityPostResponse extends CreateOrUpdatePostResponse {
}

export interface CreateOrUpdatePostResponse {
  guid: string
}

export interface CreateOrUpdateDiscussionActivityPostRequest extends CreateOrUpdatePostRequest {
}

export interface CreateOrUpdatePostRequest {
  guid?: string
  discussionGuid?: string
  parentGuid?: string
  originalGuid?: string
  authorGuid?: string
  onBehalfOfGuid?: string
  like?: boolean
  isPublished?: boolean
  isAnonymized?: boolean
  toggleIsInappropriate?: boolean
  channelType?: ChannelType
}

export interface RemovePostRequest {
  postGuid: string
}

export interface CreateOrUpdateMessageResponse {
  guid: string
  id: number
}

export interface CreateOrUpdateMessageRequest {
  guid?: string
  postGuid?: string
  text?: string
  caption?: string
}

export interface CreateOrUpdateQuestionResponse {
  guid: string
  id: number
  originalId?: number
}

export interface CreateOrUpdateQuestionRequest {
  guid?: string
  postGuid?: string
  type?: QuestionTypes
  sortOrder?: number
  text?: string
  caption?: string
  isOptional?: boolean
}

export enum QuestionTypes {
 Text = 1,
 Attachment = 2,
 Photo = 4,
 Video = 8,
 PhotoVideo = 12,
 Freestyle = 15,
}

export interface RemoveQuestionRequest {
  questionGuid: string
}

export interface CreateOrUpdateAnswerResponse {
  guid: string
  id: number
  originalId?: number
}

export interface CreateOrUpdateAnswerRequest {
  guid?: string
  questionGuid?: string
  postGuid?: string
  text?: string
  caption?: string
  originalGuid?: string
}

export interface CreateAnswersResponse {
  questionGuid: string
  answerGuid: string
}

export interface CreateAnswersRequest {
  questionGuids?: string[]
  postGuid?: string
}

export interface CreateStimulusResponse {
  guid: string
  uploadUrl?: string
  clientCode?: string
}

export interface CreateStimulusRequest {
  clientCode?: string
  messageGuid?: string
  questionGuid?: string
  answerGuid?: string
  type: StimulusType
  sortOrder: number
  mimeType?: string
  socialStimulusId?: string
  thumbnailUrl?: string
  name?: string
  postGuid?: string
  existingStimulusGuid?: string
}

export enum StimulusType {
 Photo = 0,
 Video = 1,
 Youtube = 2,
 Vimeo = 3,
 Attachment = 4,
}

export interface UpdateStimulusResponse {
  success: boolean
}

export interface UpdateStimulusRequest2 {
  guid?: string
  url?: string
  thumbnailUrl?: string
  clientCode?: string
  assetId?: string
  status: StimuliUploadState
}

export enum StimuliUploadState {
 Empty = 0,
 Uploading = 1,
 Encoding = 2,
 Valid = 3,
 Invalid = 4,
 InvalidAndNotified = 5,
 Transcribing = 6,
}

export interface RemoveStimulusRequest {
  stimulusGuid: string
}

export interface SetStimuliUrlsRequest {
  stimulusType: StimulusType
  stimuli?: Stimulus[]
}

export interface Stimulus extends AuditableEntity {
  messageId?: number
  questionId?: number
  answerId?: number
  type: StimulusType
  storageType: ClientVideoStorage
  sortOrder: number
  uploadId?: string
  assetId?: string
  url?: string
  thumbnailUrl?: string
  name?: string
  status: StimuliUploadState
  mimeType?: string
  message?: Message
  question?: Question
  answer?: Answer
  stimulusTranscription?: StimulusTranscription
}

export interface Message extends AuditableEntity {
  text?: string
  caption?: string
  post?: Post
  stimuli?: Stimulus[]
}

export interface Post extends AuditableEntity {
  discussionId: number
  parentId?: number
  originalId?: number
  authorGuid: string
  onBehalfOfGuid?: string
  hierarchy?: string
  isOpeningPost: boolean
  level: number
  likes: number
  moderationStatus?: ModerationStatus
  label?: PostLabel
  isPublished: boolean
  isAnonymized: boolean
  isInappropriate: boolean
  isPrivate: boolean
  datePublished?: DateTime
  discussion?: Discussion
  message?: Message
  questions?: Question[]
  answers?: Answer[]
  annotations?: Annotation[]
}

export enum ModerationStatus {
 Unmoderated = 0,
 UpdateRequired = 1,
 Accepted = 2,
 Other = 3,
}

export enum PostLabel {
 NoLabel = 0,
 UpdateRequired = 1,
 Updated = 2,
 Accepted = 3,
 Probed = 4,
}

export interface Discussion extends AuditableEntity {
  title?: string
  posts?: Post[]
}

export interface AuditableEntity {
  id: number
  guid: string
  dateCreated: DateTime
  userCreated?: number
  dateModified?: DateTime
  userModified?: number
}

export interface Question extends AuditableEntity {
  postId: number
  type: QuestionTypes
  sortOrder: number
  text?: string
  caption?: string
  isOptional: boolean
  post?: Post
  stimuli?: Stimulus[]
  answers?: Answer[]
}

export interface Answer extends AuditableEntity {
  postId: number
  questionId: number
  originalId?: number
  text?: string
  caption?: string
  post?: Post
  question?: Question
  stimuli?: Stimulus[]
}

export interface Annotation extends AuditableEntity {
  authorGuid: string
  postId?: number
  answerId?: number
  stimulusId?: number
  post?: Post
  answer?: Answer
  stimulus?: Stimulus
  annotationData?: AnnotationData
  annotationDataId?: number
}

export interface AnnotationData extends AuditableEntity {
  type: AnnotationType
  text?: string
  squareGuid: string
}

export enum AnnotationType {
 None = 0,
 Hashtag = 1,
 Highlight = 2,
 Like = 3,
 Mention = 4,
 Note = 5,
 Quote = 6,
 Rating = 7,
 Tag = 8,
}

export interface StimulusTranscription extends AuditableEntity {
  stimulus?: Stimulus
  jobId: number
  transcriptionOriginal?: string
  transcriptionEnglish?: string
  summaryOriginal?: string
  summaryEnglish?: string
  retries: number
}

export interface DownloadStimulusRequest {
  stimulusGuid: string
}

export interface SetVideoStimulusTranscriptionNewDiscussionsRequest extends SetVideoStimulusTranscriptionRequest {
}

export interface CreateTagResponse {
  guid: string
}

export interface CreateTagRequest2 extends CreateOrUpdateAnnotationBaseRequest {
  text?: string
}

export interface CreateOrUpdateAnnotationBaseRequest {
  postGuid?: string
  answerGuid?: string
  stimulusGuid?: string
  guid?: string
}

export interface RemoveTagRequest {
  tagGuid: string
  isFromOldDisc: boolean
}

export interface CreateOrUpdateNoteResponse {
  guid: string
}

export interface CreateOrUpdateNoteRequest extends CreateOrUpdateAnnotationBaseRequest {
  text?: string
}

export interface RemoveNoteRequest {
  noteGuid: string
}

export interface ToggleLikeRequest {
  postGuid: string
}

export interface CreateOrUpdateHighlightResponse {
  guid: string
}

export interface CreateOrUpdateHighlightRequest extends CreateOrUpdateGranularAnnotationRequest {
}

export interface CreateOrUpdateGranularAnnotationRequest extends CreateOrUpdateAnnotationBaseRequest {
  startIndex: number
  endIndex: number
}

export interface RemoveHighlightRequest {
  highlightGuid: string
}

export interface CreateOrUpdateQuoteResponse {
  guid: string
}

export interface CreateOrUpdateQuoteRequest extends CreateOrUpdateGranularAnnotationRequest {
}

export interface RemoveQuoteRequest {
  quoteGuid: string
}

export interface CreateOrUpdateRatingResponse {
  guid: string
}

export interface CreateOrUpdateRatingRequest extends CreateOrUpdateAnnotationBaseRequest {
  value: number
}

export interface CreateOrUpdateTopicResponse {
  guid: string
  discussionGuid: string
}

export interface CreateOrUpdateTopicRequest {
  guid?: string
  roomGuid?: string
  title?: string
}

export interface RemoveTopicRequest {
  topicGuid: string
}

export interface ExportOnboardingReportRequest extends ExportOptionsBase {
}

export interface ExportOptionsBase {
  segmentValuesInOneColumn: boolean
  exportPiiData: boolean
  squareParticipantGuid: string
  squareParticipantRole: Role
  squareParticipantAccessLevel: AccessLevels
  outputFilename?: string
}

export enum AccessLevels {
 None = 0,
 Square = 1,
 Team = 2,
 Lead = 4,
 TeamLead = 6,
 Full = 8,
 Developer = 16,
 FullDev = 24,
}

export interface ExportMembersRequest extends ExportOnboardingReportRequest {
  exportOnlyFilteredData: boolean
  keyword?: string
  excludeParticipantsInvitedSinceWeeks?: number
  startDate?: DateTime
  endDate?: DateTime
  selectedRoles?: Role[]
  selectedStatuses?: RegistrationStatus[]
  selectedSegments?: string[]
  selectedSegmentsOption: SelectedSegmentsOption
}

export enum RegistrationStatus {
 SingleOptInNoEmail = 0,
 SingleOptIn = 1,
 SingleOptInReminder = 2,
 DoubleOptIn = 3,
 DoubleOptInReminder = 4,
 Active = 5,
 Unsubscribed = 6,
 Anonymized = 7,
}

export enum SelectedSegmentsOption {
 None = 0,
 Any = 1,
 All = 2,
}

export interface ExportHealthReportRequest extends AdvancedExportOptions {
  startDate?: DateTime
  endDate?: DateTime
  selectedRoles?: Role[]
  selectedStatuses?: RegistrationStatus[]
  selectedSegments?: string[]
  selectedSegmentsOption: SelectedSegmentsOption
}

export interface AdvancedExportOptions extends ExportOptionsBase {
  includeKeySegmentationsOnly: boolean
  includeActiveActivitiesOnly: boolean
  exportOnlyFilteredData: boolean
}

export interface DownloadConversationRequest {
  outputFilename?: string
  conversationGuid: string
  keyword?: string
  segmentValuesInOneColumn: boolean
  exportPiiData: boolean
  sortOption: ConversationSortOption
  squareParticipantGuid: string
  squareParticipantRole: Role
  squareParticipantAccessLevel: AccessLevels
}

export enum ConversationSortOption {
 Chronological = 0,
 MostRecent = 1,
 MostLiked = 2,
}

export interface QualConversationRequestBase extends ConversationsRequestBase {
  outputFilename?: string
  activityGuid: string
  sortOption: ConversationSortOption
  segmentValuesInOneColumn: boolean
  exportOnlyFilteredData: boolean
  exportPiiData: boolean
  squareParticipantGuid: string
  squareParticipantRole: Role
  squareParticipantAccessLevel: AccessLevels
  isGroupedByMember: boolean
}

export interface ConversationsRequestBase extends ConversationFilterBase {
}

export interface ConversationFilterBase {
  forceReindex?: boolean
  noUnreadConversationsFeatureEnabled?: boolean
  keyword?: string
  startDate?: DateTime
  endDate?: DateTime
  selectedStimuliOptions?: number[]
  selectedAnnotationsOptions?: number[]
  selectedThemes?: string[]
  selectedMembers?: string[]
  selectedSegments?: string[]
  selectedSegmentsOption: SelectedSegmentsOption
  selectedRatings?: number[]
  tagsSelected?: string[]
  hashtagsSelected?: string[]
  statuses?: SquareActivityStatus[]
  selectedModerationStatusOptions?: number[]
  selectedProbeQuestions?: string[]
  sortBy: ConversationSortOption
  isDiscussionNew?: boolean
}

export enum SquareActivityStatus {
 Draft = 0,
 Scheduled = 1,
 Active = 2,
 Closed = 3,
 Archived = 4,
}

export interface ExportUserQualConversationsRequest extends ExportOptionsBase {
}

export enum ExportType {
 Undefined = 0,
 HealthReport = 1,
 OnboardingReport = 2,
 CommunicationData = 3,
 Conversation = 4,
 QualConversation = 5,
 QualConversationStimuli = 6,
 UserQualConversations = 7,
 UserQualConversationsStimuli = 8,
 ManualRewardTemplate = 9,
 MembersImportTemplate = 10,
 Members = 11,
 SavingUpRewards = 12,
 PowerpointOneColumn = 13,
 PowerpointWorkshopDeck = 14,
 ManuallyHandledRewards = 15,
 GeofencingLocationImportTemplate = 16,
 GeofencingLocationExport = 17,
}

export interface ExportPowerpointRequest {
  guid: string
  clientCode?: string
  filePath?: string
}

export interface ExportManuallyHandledRewardsRequest extends ExportOptionsBase {
  exportOnlyFilteredData: boolean
  startDate?: DateTime
  endDate?: DateTime
  keyword?: string
  clients?: string[]
  statuses?: ManuallyHandledRewardStatus[]
  squares?: string[]
  jobIds?: string[]
  squareParticipants?: number[]
  countries?: string[]
  rewardPartners?: RewardPartnerType[]
}

export enum ManuallyHandledRewardStatus {
 NotHandled = 0,
 Handled = 1,
}

export enum RewardPartnerType {
 None = 0,
 TangoCardNonEur = 1,
 Paypal = 2,
 Cadora = 3,
 TangoCardEurFrance = 4,
 TangoCardEurGermany = 5,
 TangoCardEurItaly = 6,
 TangoCardEurSpain = 7,
 Other = 8,
 Emag = 9,
 Tremendous = 10,
 PanoramaResearch = 11,
 Ovation = 12,
 UWinIwin = 13,
 Guanaitong = 14,
 GiftPay = 15,
 SavingUpRewardsCorrection = 99,
}

export interface UpdateMasterFeaturesResult {
  succeeded?: Feature[]
  failed?: UpdateMasterFeatureFailure[]
}

export enum Feature {
 None = 0,
 CheckMarket = 1,
 SavingUpRewards = 2,
 DividedDiscussionTypes = 3,
 Decipher = 4,
 ScoutDiscussionTypes = 5,
 Quota = 6,
 Confirmit = 7,
 CurationFeedbackCircle = 9,
 AnalysisAndReporting = 10,
 Discussion20New = 11,
 ActivityTargetCompletion = 13,
 ConsentMapping = 14,
 SOITargeting = 15,
 NoUnreadConversations = 16,
 AccessData = 18,
 MediaGallery = 19,
 DecipherDiary = 21,
 Captcha = 23,
 Observation = 24,
 ActivityJobIdEverywhere = 25,
 AzureAdB2CLogin = 26,
 FooterRestrictions = 27,
 SyncQualButtonHomepage = 28,
 ChatGPT = 29,
 TextAnalytics = 30,
 CustomDiscussionImport = 41,
}

export interface UpdateMasterFeatureFailure {
  feature: Feature
  errors?: string[]
}

export interface UpdateMasterFeaturesRequest {
  items?: MasterFeature[]
}

export interface MasterFeature {
  feature: Feature
  featureName?: string
  isEnabled: boolean
  isEnabledForSpecificSquares: boolean
  isExcludedForSquaresFromAllSquares: boolean
  specificSquares?: SquareMasterFeature[]
  cannotBeDisabled: boolean
}

export interface SquareMasterFeature {
  clientCode?: string
  squareId: number
}

export interface PaginatedResultOfCommandConversationElementDetailItem {
  currentPage: number
  totalPages: number
  items?: CommandConversationElementDetailItem[]
  totalItems: number
}

export interface CommandConversationElementDetailItem extends ConversationElementDetailItemBase {
  room: number
  roomGuid: string
  pageGuid: string
}

export interface ConversationElementDetailItemBase extends ConversationElementAdminModelDetailItem {
  level1DatePosted?: DateTime
  level2DatePosted?: DateTime
  level3DatePosted?: DateTime
  firstParent: number
  moderationStatus?: ModerationStatusOption
}

export enum ModerationStatusOption {
 Unmoderated = 0,
 ModeratedUpdateRequired = 1,
 ModeratedAccepted = 2,
 ModeratedOther = 3,
 AnyModerationStatus = -1,
}

export interface ConversationElementAdminModelDetailItem extends ConversationElementConsumerModelDetailItem {
  notes?: ConversationNoteListItem[]
  tags?: ConversationTagListItem[]
  hashtags?: ConversationHashtagListItem[]
  rating: number
  topic: number
  isFiltered: boolean
}

export interface ConversationNoteListItem extends CommandModelBase {
  squareParticipantGuid: string
  conversationGuid: string
  topic: number
  text?: string
  username?: string
  isEditable: boolean
}

export interface ConversationTagListItem extends TagListItemBase {
  tagGuid: string
  conversationGuid: string
  topic: number
  activityGuid: string
}

export interface TagListItemBase extends CommandModelBase {
  text?: string
}

export interface ConversationHashtagListItem extends CommandModelBase {
  hashtagGuid: string
  conversationGuid: string
  topic: number
  text?: string
  activityGuid: string
}

export interface ConversationElementConsumerModelDetailItem extends CommandModelBase {
  isExpanded: boolean
  replyCount: number
  message?: string
  caption?: string
  title?: string
  likesCount: number
  level: number
  parentMessage: string
  parentSquareParticipantGuid?: string
  firstParentGuid: string
  isLiked: boolean
  isModeratorPost: boolean
  isEditable: boolean
  dateCreated: DateTime
  datePosted?: DateTime
  impersonatorId?: number
  impersonatorGuid?: string
  impersonatorHasProfileImage?: boolean
  impersonatorDateModified?: DateTime
  impersonatorUsername?: string
  squareParticipantGuid: string
  squareParticipantId: number
  squareParticipantDateModified?: DateTime
  squareParticipantHasProfileImage: boolean
  username?: string
  email?: string
  firstName?: string
  phoneNumber?: string
  squareParticipantRole: Role
  squareParticipantStatus: RegistrationStatus
  squareParticipantAbout?: string
  suspended: boolean
  daysSinceUnsubscribed: number
  set?: number
  questionType?: ConversationQuestionType
  type: ConversationElementType
  hasBeenAnswered?: boolean
  isValidAnswer?: boolean
  unRead: boolean
  stimuli?: ConversationStimuliListItem[]
  attachments?: ConversationAttachmentListItem[]
  censored: boolean
  answerRequired: boolean
  isOneByOne: boolean
  isAccepted: boolean
  setIndex?: number
}

export enum ConversationQuestionType {
 None = 0,
 Text = 1,
 Image = 2,
 Video = 3,
 ImageAndVideo = 4,
 Attachment = 5,
 Freestyle = 6,
}

export enum ConversationElementType {
 None = 0,
 Topic = 1,
 Question = 2,
 Answer = 3,
 Reply = 4,
 IndividualUpdatePost = 5,
}

export interface ConversationStimuliListItem extends CommandModelBase {
  topic: number
  conversationGuid: string
  type: ConversationStimuliType
  storage: ClientVideoStorage
  value?: string
  url?: string
  thumbnailUrl?: string
  sortOrder: number
  isBroken: boolean
  dateCreated?: DateTime
}

export interface ConversationAttachmentListItem extends CommandModelBase {
  topic: number
  fileType?: string
  url?: string
  name?: string
  conversationGuid: string
  sortOrder: number
}

export interface ForumConversationElementsListRequest {
  conversationGuid: string
  pageNumber: number
  limit: number
  searchTerm?: string
  sortOption: ConversationSortOption
}

export interface MentionUserListItem extends CommandModelBase {
  label?: string
}

export interface GetMentionUsersForConversationRequest extends GetActivityBaseRequest {
  parentReplyGuid: string
  searchQuery?: string
}

export interface GetActivityBaseRequest {
  activityGuid: string
}

export interface PaginatedResultOfConversationListItem {
  currentPage: number
  totalPages: number
  items?: ConversationListItem[]
  totalItems: number
}

export interface ConversationListItem extends ConversationListItemBase {
  isLiked: boolean
  room: number
  roomGuid: string
  pageId: number
  totalLikes: number
  isDiscussionNewConversation: boolean
}

export interface ConversationListItemBase extends CommandModelBase {
  title?: string
  likesCount: number
  replyCount: number
  lastActivityDate: DateTime
  message?: string
  dateCreated: DateTime
  datePosted?: DateTime
  topic?: number
  parentMessage?: string
  username?: string
  squareParticipantId?: number
  squareParticipantDateModified?: DateTime
  squareParticipantGuid?: string
  squareParticipantHasProfileImage: boolean
  squareParticipantRole?: Role
  squareParticipantStatus?: RegistrationStatus
  squareParticipantAbout?: string
  suspended: boolean
  daysSinceUnsubscribed: number
  impersonatorGuid?: string
  impersonatorHasProfileImage?: boolean
  impersonatorDateModified?: DateTime
  impersonatorUsername?: string
  questionType?: ConversationQuestionType
  type: ConversationElementType
  set?: number
  isModeratorPost: boolean
  isEditable: boolean
  isAccepted: boolean
  roomName?: string
}

export interface ForumConversationListRequest extends GetPageBaseRequest {
  roomGuid: string
  pageNumber: number
  limit: number
}

export interface GetPageBaseRequest {
  pageGuid: string
}

export interface TopicNotificationCountResponse {
  topicId: number
  count: number
}

export interface ListRoomItemResponse {
  rooms?: RoomDetail[]
}

export interface RoomDetail extends RoomListItem {
  repliesCount: number
  conversationsCount: number
}

export interface RoomListItem extends CommandModelBase {
  name?: string
  pageGuid: string
  isVisible?: boolean
  squareGuid: string
}

export interface ListRoomItemRequest {
  guid: string
}

export interface RoomDetailItemResponse {
  detail?: RoomListItem
}

export interface RoomDetailItemRequest {
  guid: string
}

export interface ProbequestionsResponse {
  guid: string
  message?: string
}

export interface ForumConversationResponse extends ConversationListItemBase {
  likes?: ForumConversationLikes
  stimuli?: ConversationStimuliListItem[]
  attachments?: ConversationAttachmentListItem[]
  isRoomActive: boolean
}

export interface ForumConversationLikes {
  likeCount: number
  isLiked: boolean
}

export interface ForumConversationRequest {
  conversationGuid: string
}

export interface ConversationLocationResponse extends GetPageBaseRequest {
  roomGuid: string
  conversationGuid: string
  pageIndex: number
}

export interface ConversationLocationRequest extends GetReplyBaseRequest {
  limit: number
}

export interface GetReplyBaseRequest {
  replyGuid: string
}

export interface ListForumRoomsResponse {
  forums?: ForumDetail[]
}

export interface ForumDetail {
  name?: string
  pageGuid: string
  rooms?: RoomDetail2[]
}

export interface RoomDetail2 {
  name?: string
  roomGuid: string
}

export interface ListForumRoomsRequest {
  squareGuid: string
}

export interface PaginatedResultOfConversationSearchResultListItem {
  currentPage: number
  totalPages: number
  items?: ConversationSearchResultListItem[]
  totalItems: number
}

export interface ConversationSearchResultListItem extends ConversationListItem {
  matchCount: number
}

export interface ForumRoomSearchRequest {
  searchTerm?: string
  roomGuid: string
  currentPage: number
  limit: number
}

export interface QualConversationElementsConsumerResponse {
  latestUpdatePageIndex: number
  conversationElements?: PaginatedResultOfConversationElementConsumerModelDetailItem
  latestUpdateGuid: string
  prePopulateDescription?: string
  activityTitle?: string
  activityType: SquareActivityType
  conversationFocusType: ConversationFocusType
  contributionType?: ActivityContributionType
  visibilityBufferPeriodPassed?: boolean
  topicWithProbeQuestions?: ConversationElementConsumerModelDetailItem[]
  activitySequenceRole: ActivitySequenceRole
  activityVisibilityType: ActivityVisibilityType
}

export interface PaginatedResultOfConversationElementConsumerModelDetailItem {
  currentPage: number
  totalPages: number
  items?: ConversationElementConsumerModelDetailItem[]
  totalItems: number
}

export interface QualConversationElementsListRequest extends GetActivityBaseRequest {
  pageNumber: number
  limit: number
  sortOption: ConversationSortOption
  expandedPosts?: string[]
  expandLatestUpdatePost: boolean
  isGroupedByMember: boolean
}

export interface QualConversationElementResponse {
  guid: string
  activityGuid: string
  pageIndex: number
  expandedPosts?: string[]
}

export interface QualConversationElementRequest {
  replyGuid: string
  limit: number
}

export interface QualConversationElementsModerationRequest extends QualConversationRequestBase {
  pageNumber: number
  limit: number
  expandedPosts?: string[]
  expandLatestUpdatePost: boolean
  impersonatedUserGuid?: string
  includingNewConversationGuid?: string
}

export interface QualConversationElementsAdminResponse {
  latestUpdatePageIndex: number
  conversationElements?: PaginatedResultOfConversationElementAdminModelDetailItem
  conversationsThatMatchFilterCount: number
  latestUpdateGuid: string
  prePopulateDescription?: string
  activityTitle?: string
  activityStartDate?: DateTime
  filterDataCounters?: FilterDataCounters
  isDividedDiscussionType: boolean
  activityType: SquareActivityType
  activityEndDatePassed: boolean
  activityStatus: SquareActivityStatus
  topicWithProbeQuestions?: ConversationElementAdminModelDetailItem[]
  conversationFocusType: ConversationFocusType
  contributionType?: ActivityContributionType
  moderatorCuration: boolean
  visibilityBufferPeriodPassed: boolean
}

export interface PaginatedResultOfConversationElementAdminModelDetailItem {
  currentPage: number
  totalPages: number
  items?: ConversationElementAdminModelDetailItem[]
  totalItems: number
}

export interface FilterDataCounters {
  themes?: Record<string, number>
  segments?: Record<string, number>
  members?: Record<string, number>
  tags?: Record<string, number>
  hashtags?: Record<string, number>
  probeQuestions?: Record<string, number>
  annotationsOptions?: Record<string, number>
  stimuliOptions?: Record<string, number>
  ratings?: Record<string, number>
  moderationStatusOptions?: Record<string, number>
  conversationsCount: number
  hasDownloadableVideo: boolean
  hasDownloadableImage: boolean
}

export interface MemberQualConversationElementsAdminResponse {
  conversationsByActivity?: Record<string, ConversationElementAdminModelDetailItem[]>
}

export interface ParticipantConversationsRequest {
  squareParticipantGuid: string
  activityGuid?: string
}

export interface HashtagSquareParticipantListItem extends CommandModelBase {
  text?: string
  squareGuid: string
  squareParticipantGuid: string
  hashtagGuid: string
}

export interface GetHashtagsForConversationRequest extends GetActivityBaseRequest {
  searchQuery?: string
}

export interface GetMentionUsersForConversationAdminRequest extends GetActivityBaseRequest {
  parentReplyGuid: string
  searchQuery?: string
}

export interface GetMentionUsersForConversationForumRequest extends GetPageBaseRequest {
  searchQuery?: string
}

export interface MemberDetailsResponse {
  about?: string
  segmentationsWithSegments?: SegmentationWithSegmentsModel[]
}

export interface SegmentationWithSegmentsModel {
  segmentation?: string
  segments?: string
}

export interface MemberDetailsRequest {
  squareParticipantGuid: string
}

export interface TagListItem extends TagListItemBase {
  squareGuid: string
}

export interface GetTagsForModerationRequest extends GetActivityBaseRequest {
  searchQuery?: string
}

export interface ConversationTagDetail {
  guid: string
  text?: string
  selected: boolean
}

export interface GetConversationTagListRequest {
  activityGuid?: string
}

export interface ConversationHashtagDetail {
  guid: string
  text?: string
  selected: boolean
}

export interface GetConversationHashtagListRequest {
  activityGuid?: string
}

export interface GeofencingLocationImportValidationResponse {
  errors?: Record<string, string[]>
  warnings?: Record<string, string[]>
  total: number
  jobId?: string
  name?: string
}

export interface ImportGeofencingLocationsRequest {
  blobName?: string
  role: Role
  userGuid?: string
  squareGuid: string
  clientCode?: string
}

export interface ActivateGeofencingLocationRequest {
  locationGuid: string
  squareParticipantGuid: string
}

export interface SetDefaultGeofencingNotificationTextRequest {
  notificationText?: string
  language?: string
}

export interface ConfirmRewardWinnerDetailsRequest {
  guid: string
  fullName?: string
  addressLine1?: string
  addressLine2?: string
  zipPostalCode?: string
  city?: string
  country?: string
  stateProvince?: string
}

export interface ManualRewardImportValidationResponse {
  errors?: Record<string, string[]>
  warnings?: Record<string, string>
  total: number
  blob?: string
  warning?: string
}

export interface TriggerManualRewardRequest {
  blob?: string
  name?: string
  approvalType: SquareActivityApprovalList
}

export enum SquareActivityApprovalList {
 Manual = 0,
 Automatic = 1,
}

export interface SurvManRewardRequest {
  identifier: string
}

export interface HandleManualRewardWinnerRequest {
  guids?: string[]
}

export interface CreateNetworkResponse {
  id: number
  guid: string
}

export interface CreateNetworkRequest {
  name?: string
}

export interface UpdateNetworkResponse {
  id: number
}

export interface UpdateNetworkRequest {
  id: number
  guid: string
  name?: string
  description?: string
  jobIdList?: string[]
}

export interface NewsLikeRequest {
  communicationChannelGuid: string
  squareParticipantGuid: string
}

export interface MarkNotificationReadRequest {
  notificationGuid: string
  relatedNotificationGuids?: string[]
}

export interface PushNotificationResponse {
  successful: number
  failure: number
  responses?: Result[]
}

export interface Result {
  error?: string
  deviceToken?: string
}

export interface SendPushNotificationRequest {
  squareParticipantGuid: string
  message?: string
  title?: string
}

export interface DiscussionPostChangedRequest {
  postGuid: string
  discussionName?: string
  authorGuid: string
  onBehalfOfGuid?: string
  authorGuidForParentPost?: string
  authorGuidForOpeningPost: string
  parentLevel?: number
  activityGuid: string
  discussionPostChangeType: DiscussionPostChangeType
  contributionNumber: number
  mentions?: string[]
}

export enum DiscussionPostChangeType {
 None = 0,
 Reply = 1,
 ReplyWithRequiredQuestions = 2,
 ReplyWithOptionalQuestions = 3,
 ReplyToAll = 4,
 Mention = 5,
 Like = 6,
}

export interface RemoveLikeNotificationForDiscussionRequest {
  postGuid: string
  authorGuid: string
}

export interface PrepareParticipantForActivationRequest {
  squareParticipantGuid: string
}

export interface SaveSquareParticipantAvatarResponse {
  uri?: string
  dateModified: DateTime
}

export interface UnsubscribeSquareParticipantResponse {
  isUnsubscribed: boolean
  subscribeChangeGuid: string
}

export interface UnsubscribeSquareParticipantRequest {
  squareParticipantGuid: string
}

export interface CreateUnsubscribeReasonRequest {
  squareParticipantGuid: string
  unsubscribeReason?: string
}

export interface ReactivateParticipantStatusResponse {
  isReactivated: boolean
}

export interface ReactivateParticipantStatusRequest {
  squareParticipantChangeRequestGuid: string
}

export interface RegisterSquareParticipantResponse {
  id: number
  guid: string
  error?: string
  errorDescription?: string
  token?: string
}

export interface RegisterSquareParticipantRequest {
  squareParticipantGuid: string
  username?: string
  password?: string
  confirmPassword?: string
  email?: string
  firstName?: string
  lastName?: string
  reCaptchaResponse?: string
  directlyAddToAzureAd: boolean
}

export interface CreateSquareParticipantResponse {
  id: number
  guid: string
  participantId: number
}

export interface CreateExternalParticipantRequest {
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  addressline1?: string
  addressline2?: string
  zipPostalCode?: string
  city?: string
  country?: string
  stateRegion?: string
  role: Role
  sendActivationEmail: boolean
  overruledLabels?: Record<string, string>
  isActivationEmailEdited: boolean
  consentValidityDate?: DateTime
  source: SquareParticipantSource
}

export enum SquareParticipantSource {
 Screener = 1,
 Import = 2,
 Individual = 3,
 NavisionSync = 4,
}

export interface SendActivationEmailRequest {
  squareParticipantGuid: string
  overruledLabels?: Record<string, string>
  isActivationEmailEdited: boolean
  isTemplateShown: boolean
  customEmailGuid?: string
  allowActiveSquareParticipantForAd: boolean
}

export interface SaveParticipantActivationResponsesRequest {
  squareParticipantGuid: string
  responses?: ParticipantActivationResponse[]
  isMigrating: boolean
  communicationEmail?: string
}

export interface ParticipantActivationResponse {
  questionGuid: string
  response?: string
}

export interface ResetPasswordResponse {
  error?: string
  errorDescription?: string
  squares?: SquareDetails[]
}

export interface SquareDetails {
  guid: string
  name?: string
  clientCode?: string
  clientGuid: string
  language?: string
  url?: string
  squareDomain?: string
  baseColor?: string
  apiBaseUrl?: string
  userToken?: string
  unAgreedSplashMessage?: SplashMessageResponse
}

export interface SplashMessageResponse {
  version: string
  message?: string
  agreeLabel?: string
  disagreeLabel?: string
}

export interface ResolveAppResetPasswordDetailsResponse {
  items?: AppResetPasswordDetailItem[]
}

export interface AppResetPasswordDetailItem {
  squareParticipantId: number
  squareParticipantGuid: string
  participantId: number
  squareGuid: string
  squareSubdomain?: string
  squareDomain?: string
  squareName?: string
  squareLanguage?: string
  squareBaseColor?: string
  clientCode?: string
  changeRequestGuid: string
}

export interface ResolveAppResetPasswordDetailsRequest {
  email?: string
}

export interface ResetPasswordRequest {
  email?: string
  userHostAddress?: string
}

export interface ChangePasswordResponse {
  isChanged: boolean
  email?: string
}

export interface ChangePasswordRequest {
  password?: string
  confirmPassword?: string
  squareParticipantChangeRequestGuid: string
}

export interface ChangeEmailResponse {
  isChanged: boolean
  email?: string
}

export interface ChangeEmailRequest {
  squareParticipantChangeRequestGuid: string
}

export interface UpdateSquareParticipantResponse {
  id: number
  guid: string
}

export interface UpdateSquareParticipantDetailRequest {
  squareParticipantGuid: string
  username?: string
  userType: Role
  userClaimsName?: string
  email?: string
  currentPassword?: string
  password?: string
  confirmPassword?: string
  phoneNumber?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  stateRegion?: string
  zipPostalCode?: string
  firstName?: string
  lastName?: string
  about?: string
  isBasicInfoChanged: boolean
  communicationEmail?: string
  communicationEmailCode?: string
}

export interface SquareParticipantDetailResponse {
  detail?: SquareParticipantDetailItem
}

export interface SquareParticipantDetailItem extends CommandModelBase {
  squareId: number
  squareGuid: string
  participantId: number
  status: RegistrationStatus
  lastLoginDate?: DateTime
  claimsName?: string
  dateCreated?: DateTime
  email?: string
  username?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  hasProfileImage: boolean
  role: Role
  suspended: boolean
  anonymousSquare: boolean
  communicationEmail?: string
  platformTourStarted: boolean
}

export interface AppLoginResponse {
  firstname?: string
  squares?: SquareDetails[]
}

export interface AppLoginRequest {
  email?: string
  password?: string
}

export interface LinkMobileUserToSquareResponse {
  token?: string
}

export interface LinkMobileUserToSquareRequest {
  squareGuid: string
}

export interface AppEmailAvailableRequest {
  email?: string
}

export interface MobileAppLogoutRequest {
  mobileId?: string
}

export interface CreateMobileAppUserResponse {
  unAgreedSplashMessage?: SplashMessage
}

export interface SplashMessage extends CommandModelBase {
  squareGuid: string
  isEnabled: boolean
  message?: string
  agreeLabel?: string
  disagreeLabel?: string
}

export interface CreateMobileAppUserRequest {
  email?: string
  mobileId?: string
  mobileType?: string
  appVersion?: string
  notificationServiceType: MobileNotificationServiceType
}

export enum MobileNotificationServiceType {
 Firebase = 1,
 AzureNotificationHubs = 2,
}

export interface UpdateMemberDetailRequest {
  guid: string
  email?: string
  username?: string
  about?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  addressLine1?: string
  addressLine2?: string
  zipPostalCode?: string
  city?: string
  country?: string
  stateRegion?: string
  role: Role
  status: RegistrationStatus
  locked: boolean
  suspendRequest?: ChangeSuspendStatusRequest
}

export interface ChangeSuspendStatusRequest {
  shouldSuspend: boolean
  suspendReason?: SuspendReason
  comment?: string
}

export enum SuspendReason {
 Unknown = 0,
 SuspendedPanelParticipant = 1,
 JobIdRemoval = 2,
 NotActiveInNavison = 3,
 SuspendedLowQuality = 4,
 SuspendedOther = 5,
 BlackListedFraudulent = 6,
 BlacklistedSpam = 7,
 BlacklistedBot = 8,
 BlacklistedOther = 9,
 RightToBeForgotten = 10,
 SourceConsentExpiration = 11,
 SwitchingLanguage = 12,
 NotSuspended = -1,
}

export interface MemberImportValidationResponse {
  errors?: Record<string, string[]>
  warnings?: Record<string, string[]>
  total: number
  jobId?: string
  name?: string
}

export interface TriggerMassUploadTaskRequest {
  sendActivationEmail: boolean
  name?: string
  role: Role
  country?: string
  overruledLabels?: Record<string, string>
  isActivationEmailEdited: boolean
  isTemplateShown: boolean
  consentValidityDate?: DateTime
}

export interface BulkChangeRoleRequest extends BulkMemberRequest {
  role: Role
}

export interface BulkMemberRequest {
  squareParticipantGuids?: string[]
}

export interface BulkSendActivationRequest extends BulkMemberRequest {
  overruledActivationLabels?: Record<string, string>
  overruledReminderLabels?: Record<string, string>
  isTemplateEdited: boolean
  isTemplateShown: boolean
}

export interface BulkChangeStatusRequest extends BulkMemberRequest {
  suspendRequest?: ChangeSuspendStatusRequest
}

export interface Segmentation2 extends CommandModelBase {
  question?: string
  squareGuid: string
  type: SegmentationType
}

export interface Segment extends CommandModelBase {
  answer?: string
  segmentationGuid: string
  usedBy: number
}

export interface BulkSegmentUsageRequest extends BulkMemberRequest {
  segmentationGuid: string
}

export interface BulkChangeSegments extends BulkMemberRequest {
  segments?: SegmentAssignEqualityComparer[]
}

export interface SegmentAssignEqualityComparer {
  segmentGuid: string
  action: SegmentAction
  isAllowedForModerators: boolean
}

export enum SegmentAction {
 Add = 0,
 Remove = 1,
}

export interface GetSquareParticipantGuidsAndUsernamesResponse {
  squareParticipantsFromDb?: Record<string, string>
}

export interface GetSquareParticipantGuidsAndUsernamesRequest {
  usernames?: string[]
  guids?: string[]
}

export interface MemberWithReplyImportValidationResponse extends MemberImportValidationResponse {
  totalReplies: number
  totalSegmentations: number
  totalSegments: number
  settings?: MemberWithReplyImportSettings
}

export interface MemberWithReplyImportSettings {
  protocol?: string
  location?: string
  username?: string
  password?: string
  'default_extension'?: string
  'default_email_domain'?: string
  'abort_on_error'?: string
  'rollback_on_error'?: string
  'segmentations_start_column_index'?: string
  'segmentations_end_column_index'?: string
  'only_members'?: string
}

export interface TriggerCustomActivityUploadTaskRequest {
  userGuid: string
  role: Role
  activityGuid: string
  name?: string
  settings?: MemberWithReplyImportSettings
  userId: number
}

export interface RegisterEmailAddressForADActivationResponse {
  success: boolean
  migrated: boolean
  adEmail?: string
  socialLoginType: SocialLoginType
  squareParticipantGuid?: string
  isAdmin: boolean
  isEmailAlreadySent: boolean
}

export enum SocialLoginType {
 None = 0,
 Facebook = 1,
 Google = 2,
 Apple = 3,
 WeChat = 4,
}

export interface SquareParticipantCommunicationEmailValidationResponse {
  success: boolean
  error?: string
}

export interface SquareParticipantCommunicationEmailValidationRequest {
  squareParticipantGuid: string
  communicationEmail?: string
}

export interface SquareParticipantCommunicationEmailValidationCodeResponse extends SquareParticipantCommunicationEmailValidationResponse {
}

export interface SquareParticipantCommunicationEmailValidationCodeRequest extends SquareParticipantCommunicationEmailValidationRequest {
  code?: string
}

export interface CreateEmailEventRequest {
  requestData?: CreateEmailEventRequestDataList[]
}

export interface CreateEmailEventRequestDataList {
  squareParticipantId: number
  emailEventsList?: CreateEmailEventRequestData[]
}

export interface CreateEmailEventRequestData {
  emailType?: string
  eventType?: string
  attemptNr: number
  recipientEmail?: string
  timeStamp: DateTime
  ipAddr?: string
  userAgent?: string
  urlClicked?: string
  emailTemplate?: string
  messageId?: string
  squareParticipantId: number
  refGuid?: string
}

export interface MailgunWebhookData {
  signature?: MailgunSignature
  'event-data'?: MailgunWebhookEventData
}

export interface MailgunSignature {
  token?: string
  timestamp?: string
  signature?: string
}

export interface MailgunWebhookEventData {
  event?: string
  severity?: string
  timestamp: number
  message?: Message2
  'delivery-status'?: DeliveryStatus
  'user-variables'?: Record<string, string>
}

export interface Message2 {
  headers?: Headers
}

export interface Headers {
  'message-id'?: string
  from?: string
  to?: string
  subject?: string
}

export interface DeliveryStatus {
  'attempt-no': number
}

export interface LogVideoStatisticsFromClientRequest {
  uploadId?: string
  sizeInMb?: number
  extension?: string
  mimeType?: string
  isMobile: boolean
  isMobileApp: boolean
  dateUploadRequested?: DateTime
  dateUploadFinished?: DateTime
  dateVideoReady?: DateTime
  assetId?: string
}

export interface HealthCheckEventJobRequest {
  checkEvent: boolean
  checkJob: boolean
}

export interface CreatePlatformNoticeRequest {
  noticeType: PlatformNoticeType
  startNoticeDateTime?: DateTime
  endNoticeDateTime?: DateTime
  customMessage?: string
  startDateTime?: DateTime
  lengthOfMaintenance?: number
  targetInSites: boolean
  targetModerators: boolean
  targetObservers: boolean
}

export enum PlatformNoticeType {
 None = 0,
 DefaultMaintenance = 1,
 CustomNotice = 2,
}

export interface CreateProgramResponse {
  id: number
  guid: string
  jobId?: string
}

export interface CreateProgramRequest {
  clientGuid: string
  name?: string
}

export interface UpdateProgramResponse {
  id: number
}

export interface UpdateProgramRequest {
  id: number
  guid: string
  name?: string
  description?: string
  creditsReminderEmail?: string
}

export interface PinProgramRequest {
  clientGuid: string
  programGuid: string
}

export interface UpdateJobIdReminderEmailResponse {
  jobIdReminderEmail?: string
}

export interface UpdateJobIdReminderEmailRequest {
  programGuid: string
  email?: string
}

export interface ProgramCredits extends CommandModelBase {
  creditJobId?: string
  programGuid: string
  type: ProgramCreditsType
  totalCredits: number
  startDate?: DateTime
  creditsLeft: number
  dateExpired?: DateTime
  lastReminderDate?: DateTime
}

export enum ProgramCreditsType {
 Native = 0,
 Decipher = 1,
}

export interface UpdateProgramCreditsRequest {
  programCredits?: CreateUpdateProgramCreditsRequest[]
  programGuid: string
}

export interface CreateUpdateProgramCreditsRequest {
  creditJobId?: string
  type: ProgramCreditsType
  totalCredits: number
  startDate?: DateTime
  creditsLeft: number
  dateExpired?: DateTime
  guid: string
}

export interface ProgramCreditsPerActivityType extends CommandModelBase {
  programGuid: string
  forumDiscussion: number
  privateDiscussion: number
  lifeSnapshots: number
  lifeScenes: number
  survey: number
}

export interface UpdateProgramCreditsPerActivityTypeRequest {
  programGuid: string
  forumDiscussion: number
  privateDiscussion: number
  lifeSnapshots: number
  lifeScenes: number
  survey: number
}

export interface UpdateWinnerRequest {
  winnerGuid: string
  isApproved: boolean
  approverSquareParticipantGuid: string
  rewardPartner: RewardPartnerType
}

export interface CreateUpdateSavingUpRewardsSettingsRequest {
  featureSettings?: UpdateFeatureSettingsRequest
}

export interface UpdateFeatureSettingsRequest {
  settings?: Record<string, string>
  enabled: boolean
  feature: Feature
}

export interface UpdatePeriodicRaffleRequest {
  raffleGuid: string
  name?: string
  startDate: DateTime
  offset: number
  endDate: DateTime
  executionDate?: DateTime
  rewards?: RaffleReward[]
}

export interface CreatePeriodicRaffleRequest {
  name?: string
  offset: number
  startDate: DateTime
  endDate: DateTime
  executionDate?: DateTime
  rewards?: RaffleReward[]
}

export interface DeleteSegmentationRequest {
  segmentationGuid: string
}

export interface ToggleFavoriteSegmentRequest {
  segmentationGuid: string
}

export interface TogglePublicSegmentationRequest {
  segmentationGuid: string
}

export interface DeleteAnswerForSegmentRequest {
  segmentGuid: string
  answerGuid: string
}

export interface ToggleSegmentationIsArchivedRequest {
  segmentationGuid: string
}

export interface SaveSegmentationResponse {
  segmentationGuid: string
  segmentGuidsForName?: Record<string, string>
}

export interface SaveSegmentationRequest {
  guid?: string
  name?: string
  type?: SegmentationType
  segments?: SaveSegmentItem[]
}

export interface SaveSegmentItem {
  guid?: string
  name?: string
  precode?: string
}

export interface ToggleActivitySegmentationResponse {
  segmentationGuid: string
  segmentationName?: string
}

export interface ToggleActivitySegmentationRequest {
  activityGuid: string
  isDisable: boolean
}

export interface LinkSegmentsAnswersRequest {
  surveyQuestionGuid: string
  links?: LinkSegmentAnswerRequest[]
}

export interface LinkSegmentAnswerRequest {
  segmentGuid: string
  answerGuid?: string
}

export interface CreateSquareResponse {
  id: number
  guid: string
  updateSquareUrlJobId?: string
}

export interface CreateSquareRequest {
  name?: string
  programGuid: string
  clonedSquareGuid?: string
}

export interface UpdateSquareResponse {
  guid: string
  notClonedSections?: string
  hasSsoData: boolean
  status: SquareStatus
  statusString?: string
  jobIds?: SquareJob[]
}

export enum SquareStatus {
 Draft = 0,
 Scheduled = 1,
 Active = 2,
 Closed = 3,
 Archived = 4,
 Deleted = 5,
}

export interface SquareJob extends CommandModelBase {
  jobId?: string
  squareGuid: string
  startDate?: DateTime
  isSelfServe: boolean
  commitment?: number
  status: JobIdStatus
  lastReminderDate?: DateTime
}

export enum JobIdStatus {
 Legacy = 0,
 NotStarted = 1,
 Active = 2,
 Elapsed75Perc = 3,
 Elapsed90Perc = 4,
 Expired = 5,
 Paused = 6,
}

export interface UpdateSquareRequest {
  id: number
  guid: string
  name?: string
  status: SquareStatus
  startDate: DateTime
  startDateOffset: number
  adLandingPage: boolean
  openRegistrationScreenerSurvey?: string
  language?: string
  url?: string
  jobIds?: CreateUpdateJobIdRequest[]
  ssoEnabled: boolean
  welcomeBodyResearcher?: string
  welcomeBodyObserver?: string
  welcomeBodyParticipant?: string
  digestEmailFrequencyDays: number
  digestMailTime: DateTime
  digestEmailOffset: number
  isDigestEmailEnabled: boolean
  ssoExternalLoginUrl?: string
  closureMessage?: string
  featureSettings?: UpdateFeatureSettingsRequest[]
  anonymous: boolean
  isPublicProfileEnabled: boolean
  countries?: string[]
  adVerification: boolean
  adFlowSuffix?: string
  shouldShowAppStoreLinksInEmailFooter: boolean
  shouldForceMobileApp: boolean
  shouldShowAppStoreLinksOnMobileWeb: boolean
  passwordLength: number
  hasStrongPasswordEnabled: boolean
  allowPiiInExports: boolean
  saveAsDraft: boolean
}

export interface CreateUpdateJobIdRequest {
  jobId?: string
  squareGuid: string
  startDate?: DateTime
  commitment?: number
  status?: JobIdStatus
  guid: string
  isSelfServe?: boolean
}

export interface UpdateProgramSquareJobsResponse extends SquareJob {
  squareName?: string
}

export interface UpdateProgramSquareJobsRequest {
  jobIds?: CreateUpdateProgramJobIdRequest[]
}

export interface CreateUpdateProgramJobIdRequest extends CreateUpdateJobIdRequest {
  isSelfServe: boolean
  squareName?: string
}

export interface ToggleSquareArchiveRequest {
  squareGuid: string
}

export interface SaveInvalidateJobIdRequest {
  squareGuid: string
  jobId?: string
}

export interface LinkConnectProjectRequest {
  squareGuid: string
  clientGuid: string
  projectUrl?: string
  projectName?: string
}

export interface SyncPageResponse {
  id: number
  guid?: string
}

export interface SyncHomePageRequest extends SyncPageRequest {
}

export interface SyncPageRequest {
  id?: number
  guid?: string
  name?: string
  icon?: string
  intro?: string
  squareGuid: string
  area: Area
  pageType: PageType
  operationType: OperationType
  segmentGuid?: string
  isCardOrderFifo: boolean
  isAboutThisProjectPage: boolean
  pageId: number
  pageGuid: string
  displayOrder?: number
  showInMobileNavBar: boolean
}

export enum Area {
 Undefined = 0,
 MainPages = 1,
 HelpCenter = 2,
 Footer = 3,
 Incentives = 4,
 Splash = 5,
}

export enum PageType {
 Home = 1,
 Activities = 2,
 Forum = 3,
 Ideabox = 4,
 News = 5,
 MainPageCustom = 6,
 FAQ = 7,
 PlatformTour = 8,
 Contact = 9,
 HelpCenterCustom = 10,
 FooterCustom = 11,
 IncentivesCustom = 12,
 MyIncentive = 13,
 RewardPolicy = 14,
 SavingUpRewardPolicy = 15,
 MyCookieSettings = 16,
 CookiePolicy = 17,
 Profile = 18,
}

export enum OperationType {
 Unchanged = 0,
 Create = 1,
 Update = 2,
 Delete = -1,
}

export interface SyncActivitiesPageRequest extends SyncPageRequest {
  activityThemes?: ActivityThemeRequest[]
}

export interface ActivityThemeRequest {
  guid?: string
  isDefaultTheme: boolean
  name?: string
  sortOrder: number
}

export interface SyncLoungePageRequest extends SyncPageRequest {
  rooms?: ForumRoomRequest[]
}

export interface ForumRoomRequest {
  id?: number
  guid?: string
  operationType: OperationType
  name?: string
  isVisible?: boolean
  pageGuid: string
}

export interface SyncIdeaboxPageRequest extends SyncPageRequest {
}

export interface SyncNewsPageRequest extends SyncPageRequest {
}

export interface SyncCustomPageRequest extends SyncPageRequest {
  editUrl?: string
  customUrl?: string
  openInNewTab: boolean
}

export interface SyncFaqPageRequest extends SyncPageRequest {
  categories?: FaqCategoryRequest[]
  questions?: FaqQuestionRequest[]
}

export interface FaqCategoryRequest {
  guid: string
  name?: string
  isVisible: boolean
  type: FaqCategoryType
  isLinkedToIncentives?: boolean
  operationType: OperationType
  pageGuid: string
  questions?: FaqQuestionRequest[]
}

export enum FaqCategoryType {
 None = 0,
 ManualRewards = 1,
 SavingUpRewards = 2,
 ManualSavingUpRewards = 3,
}

export interface FaqQuestionRequest {
  guid: string
  title?: string
  isVisible: boolean
  answer?: string
  faqCategoryGuid: string
  operationType: OperationType
}

export interface SyncPlatformTourPageRequest extends SyncPageRequest {
  isEnabled: boolean
}

export interface SyncContactPageRequest extends SyncPageRequest {
  firstName?: string
  lastName?: string
  email?: string
  image?: string
}

export interface SyncHelpCenterCustomPageRequest extends SyncCustomPageRequest {
}

export interface PinSquareRequest {
  squareGuid: string
  userId?: number
  canBeRemoved: boolean
  clientGuid: string
}

export interface SyncFooterCustomPageRequest extends SyncCustomPageRequest {
}

export interface SyncCookiePolicyPageRequest extends SyncCustomPageRequest {
}

export interface SyncIncentivesCustomPageRequest extends SyncCustomPageRequest {
}

export interface SyncMyIncentivePageRequest extends SyncPageRequest {
  isEnabled: boolean
}

export interface SaveSquareActivationQuestionsRequest {
  squareGuid: string
  questions?: SquareActivationQuestion[]
}

export interface SquareActivationQuestion {
  guid: string
  used: boolean
}

export interface SaveSquareColorsRequest {
  baseColor1?: string
  navigationBarLeft?: string
  navigationBarRight?: string
  navigationBarText?: string
  backgroundColor?: string
  pageColor?: PageColor[]
}

export interface PageColor {
  pageGuid: string
  color?: string
}

export interface DeleteDesignImagesRequest {
  imageNames?: string[]
}

export interface SaveSquareCustomCssRequest {
  customCss?: string
  isEmail: boolean
}

export interface SaveSquareAdLandingPageConfigRequest {
  hasTopLogo: boolean
  hasBackground: boolean
  title?: string
  text?: string
}

export interface SaveSquareSocialLoginConfigRequest {
  types?: SquareSocialLoginType[]
}

export interface SquareSocialLoginType {
  name?: string
  enabled: boolean
}

export interface SaveSplashMessageRequest {
  guid: string
  isEnabled: boolean
  message?: string
  agreeLabel?: string
  disagreeLabel?: string
  newVersion: boolean
}

export interface SaveSplashAgreementRequest {
  agreed: boolean
  version: string
}

export interface TargetingQuotaResponse {
  targetingQuotaItems?: TargetingQuotaItem[]
  segmentationGuids?: string[]
  dateLastFeasibilityCheck?: DateTime
}

export interface TargetingQuotaItem {
  segmentGuids?: string[]
  segmentName?: string
  targetingQuotaItemRows?: TargetingQuotaItemRow[]
}

export interface TargetingQuotaItemRow {
  value: boolean
  amountInTarget: number
  amountAvailable: number
  amountRandom: number
}

export interface TargetingQuotaRequest {
  activityGuid: string
  targeting?: Targeting
}

export interface Targeting {
  targeted: number
  targetedFrom: number
  randomlySelected?: number
  excludeParticipantsInvitedSinceWeeks?: number
  matches?: number
  targetingFilterOption: TargetingFilterOption
  targetingFilterType: TargetingFilterType
  items?: TargetingItem[]
  squareParticipantIds?: number[]
  targetingType?: TargetingType
  hasQuota: boolean
  targetingQuota?: TargetingQuota
}

export enum TargetingFilterOption {
 None = 1,
 Any = 2,
 All = 3,
}

export enum TargetingFilterType {
 None = 0,
 BySegmentations = 1,
 ByMemberIds = 2,
 All = 3,
}

export interface TargetingItem {
  id: number
  segmentationGuid?: string
  segmentGuids?: string[]
  targetingFilterOption: TargetingFilterOption
}

export enum TargetingType {
 Activity = 0,
 Communication = 1,
}

export interface TargetingQuota {
  segmentationGuids?: string[]
  targetingQuotaItems?: TargetingQuotaItem[]
  totalRandom: number
  dateLastFeasibilityCheck?: DateTime
}

export interface CreateUpdateTargetingRequest {
  targeting?: Targeting
  communicationGuid?: string
  activityGuid?: string
  squareParticipantGuid?: string
  onBehalfOfSquareParticipantGuid?: string
  isClonedActivity: boolean
  completionTarget?: number
  activitySegmentTargets?: ActivitySegmentTargetCreateUpdate[]
  forceTargeting: boolean
}

export interface ActivitySegmentTargetCreateUpdate {
  segmentGuid: string
  target: number
}

export interface RemoveSamplingRequest {
  communicationGuid: string
  removeMainSample: boolean
}

export interface CompleteSampleRequest {
  sampleGuid: string
  outcomeCode: SurveyOutcomeCode
  activityFormat: SquareActivityFormat
  channelType?: ChannelType
}
