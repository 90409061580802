import { getReplyForumRoute } from '@/common/utils/conversation';
import { hasNullSsoToken, hasSsoToken, showLimitedAccessDialog, ssoLogin, getSsoTokenFromUrl } from '@/common/utils/sso';
import { onBehalfOfGuidFromURI, toConversationParamFromURI } from '@/common/utils/url';
import { useSquareStore } from '@/store/square-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { RouteNames } from './route-names.enum';
import { AUTHENTICATION_BASE } from '@/common/env.config';

const getRedirectRoute = async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
  const squareStore = useSquareStore();

  const communicationParams = toConversationParamFromURI(to);
  if (!hasSsoToken(to) && !squareStore.info.adLogin) {
    if (communicationParams.conversationGuid) {
      return getReplyForumRoute(communicationParams.conversationGuid, communicationParams.redirectType);
    }

    return false;
  }

  if (!hasSsoToken(to) || hasNullSsoToken(to) || to?.query.token === '') {
    if (squareStore.info.adLogin) {
      // this square has participant AD login feature,
      // so we are calling the authentication service with this route as return Uri
      // !!!! IMPORTANT go to the member path for authentication service
      // because it is using the sign in user flow for participants
      return getRedirectRouteForAdLogin(
        to, squareStore.info.language, squareStore.info.adVerification, squareStore.info.adLandingPage,
        squareStore.info.adFlowSuffix);
    }
    return { name: RouteNames.LoginError };
  }

  const token = squareStore.info.ssoEnabled || squareStore.info.adLogin
    ? await ssoLogin(to)
    : getSsoTokenFromUrl(to);

  // ssoLogin might return a route location instead of a token
  // if it does, we will return the route
  const route = token as RouteLocationNormalized;
  if (!route && !token) {
    return false;
  }

  if (route && route.name) {
    return route;
  }

  const authStore = useAuthenticationStore();
  await authStore.setToken(token as string);

  await authStore.setImpersonate(to.query.on_behalf_of?.toString());

  if (communicationParams.conversationGuid) {
    return getReplyForumRoute(communicationParams.conversationGuid, communicationParams.redirectType);
  }

  if (squareStore.info.adLogin === true
    && to.query?.from_admin !== '1'
    && to.query?.from_observer !== '1') {
    // redirect to MM if needed, based on square participant role
    await authStore.postLoginAction(false);
    return false;
  }

  return { name: RouteNames.Home };
};

const getRedirectRouteForAdLogin = (to: RouteLocationNormalized, language: string | undefined,
  adVerification: boolean, adLandingPage: boolean, adFlowSuffix: string | undefined) => {
  const returnUri = new URL(window.location.origin);
  returnUri.pathname = 'sso';
  if (to.query.identity) {
    returnUri.searchParams.append('identity', to.query.identity.toString());
  }
  const authUrl = new URL(AUTHENTICATION_BASE);
  authUrl.pathname = to.query.isAdmin === '1' ? 'member/admin' : 'member';
  authUrl.searchParams.append('return_uri', encodeURIComponent(returnUri.toString()));
  let flowSuffix = adFlowSuffix || '';
  if (to.query.flow) {
    // besides the flow suffix from square info, there could be another one which means a precise social type
    flowSuffix = `${flowSuffix}-${to.query.flow}`;
  }
  if (flowSuffix) {
    authUrl.searchParams.append('flow', flowSuffix);
  }
  if (language) {
    authUrl.searchParams.append('locale', language);
  }

  // if ad verify is on and there is no param that says he went through the verify it is you return to this page
  // otherwise go to login
  if(!to.query.isVerified) {
    if (adLandingPage) {
      return { name: RouteNames.ADOpenRegistration };
    } else if (adVerification) {
      return { name: RouteNames.ADRegistration };
    }
  }

  const queryEmail = to?.query.email;
  if (queryEmail && queryEmail.toString()) {
    authUrl.searchParams.append('email', queryEmail.toString());
  }
  window.location.href = authUrl.toString();
  return false;
};

export const ssoRoute: RouteRecordRaw = {
  name: RouteNames.SSO,
  path: '/sso',
  component: {},
  meta: {
    title: '',
    requireLogin: false,
  },
  beforeEnter: async (to) => {
    try {
      if (to.query.invalid) {
        const invalidRoute: NavigationGuardReturn = { name: RouteNames.SignInError };
        if (to.query.noaccount && useSquareStore().info.adLandingPage === true) {
          invalidRoute.name = RouteNames.ADOpenRegistration;
          invalidRoute.query = { noaccount: 1 };
        }
        return invalidRoute;
      }
      const redirectRoute = await getRedirectRoute(to);
      const squareParticipantGuid = onBehalfOfGuidFromURI(to);

      if (squareParticipantGuid && typeof redirectRoute === 'object') {
        redirectRoute.query = {
          ...redirectRoute.query,
          onBehalfOf: squareParticipantGuid,
        };
      }

      return redirectRoute;
    } catch {
      const signout = async (invalidToken: boolean) => {
        const authStore = useAuthenticationStore();
        await authStore.signout(false, invalidToken);
      };
      if (hasNullSsoToken(to)) {
        try {
          showLimitedAccessDialog(true, () => signout(false));
        } catch {
          await signout(false);
        }
      } else {
        const hasToken = hasSsoToken(to);
        await signout(hasToken);
        if (hasToken) {
          return false;
        }
      }

      return { name: useSquareStore().info.adLogin ? RouteNames.Home : RouteNames.Login };
    }
  },
};


